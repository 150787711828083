.core-maps .core-maps_page-header {
  margin-top: 1.5rem;
  margin-left: 2.5rem;
  margin-bottom: 0.75rem;
}

.core-maps .core-maps_page-header .title {
  font-weight: 700;
  font-size: 1.286em;
}

.core-maps .core-maps_header {
  margin: 0 0.2rem;
  padding: 1.8em 2.5rem;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1em;
  z-index: 1001;
}

.core-maps .core-maps_headings h2 {
  margin-bottom: 0;
}

.core-maps .core-maps_filters {
  display: flex;
  flex-flow: row-reverse wrap-reverse;
  gap: 0.75rem;
  flex-grow: 1;
}

.core-maps .core-maps-options-filters {
  display: flex;
  flex-flow: row wrap;
  column-gap: 0.75rem;
}

.core-maps .core-maps-options-filters > div {
  border-left: 1px solid #d7e2eb;
  padding-left: 1rem;
}

.core-maps .core-maps_time-and-estate-filters {
  padding: 0;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: space-between;
}

.core-maps .radio-buttons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.625rem;
}

.core-maps h2 {
  margin-bottom: 0.25rem;
  font-size: 1rem;
  font-weight: 700;
}

.core-maps h3 {
  margin-bottom: 1.25rem;
  font-size: 0.825rem;
  font-weight: 500;
}

.core-maps .loading-container {
  height: 75vh;
}
