.cluster-map-container {
  display: flex;
  flex-direction: row;
  padding: 1.8em 2.5rem;
  padding-top: 0;
  gap: 1rem;
}

.cluster-map-container .devices-card {
  background-color: #f3f7fb;
}

.cluster-map-container .devices-card .devices-card_header {
  padding-bottom: 1rem;
  border-bottom: 1px solid #C2D1E0;
  margin-bottom: 1rem;
}

.cluster-map-container .devices-card .devices-card_header h3 {
  font-weight: 700;
  margin-bottom: 0;
}

.cluster-map-container .devices-card p:last-child {
  margin-bottom: 0;
}

.cluster-map .device-stats {
  background-color: white;
  padding: 0.5rem;
  border: 2px solid;
  border-radius: 0.25rem;
  opacity: 0.3;
  transition-property: opacity, color, border-color;
  transition-duration:  0.25s;
  border-color: rgb(0 0 0 / 30%);
  color: black;
}

.cluster-map .device-stats:hover {
  opacity: 1;
  color: black;
  border-color: rgb(0 0 0 / 30%);
}

.cluster-map .bottom-left-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding-right: 10px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.cluster-map .cluster {
  animation: fade-in 0.25s;
  animation-fill-mode: forwards;
}

.cluster-map .cluster-label::before {
  display: none;
}

.cluster-map .cluster-label {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
  transform: translateX(50%);
  text-align: center;
  translate: 0% -50%;
  color: rgb(255 255 255 / 100%);
  -webkit-text-stroke-color: rgb(25 121 230 / 100%);
  -webkit-text-stroke-width: 1.5px;
}

.cluster-map .cluster-label.loading {
  color: rgb(255 255 255 / 0%);
  -webkit-text-stroke-color: rgb(25 121 230 / 0%);
}
